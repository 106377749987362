import React, { useEffect, useState } from "react";
import Title from "../../Dashboard/Title";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  TableCell,
  Popover,
  MenuList,
  MenuItem,
  Modal,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddQuestion from "./AddQuestion";
import Edit from "./Edit";
import axios from "axios";
import LoaderLine from "../../LoaderLine";
import { RiDeleteBin6Line } from "react-icons/ri";
import api from "../../../services/assignment";
import { CachedOutlined } from "@mui/icons-material";
import EditQuestion from "./EditQuestion";
import { useSnackbar } from "notistack";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useGetAllAssignmentsQuery } from "../../../api/assigmentAPi";
import { useSelector } from "react-redux";

const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const stylePrevent = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "1px solid #671E78",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
  height: 200,
  overflow: "scroll",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "rgba(103, 30, 120, 0.1)",
    fontFamily: "Gilroy-Bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Gilroy-Regular",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: " rgba(103, 30, 120, 0.1)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const AddAssignment = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [questions, setQuestions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleCloseDelete = () => setOpenDelete(false);
  const [questionId, setQuestionId] = React.useState("");
  const [editData, setEditData] = React.useState({});
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const adtoken = localStorage.getItem("admToken");
  const subject = queryParams.get("subject");
  const isActivate = queryParams.get("isActivate");
  const isActivateBoolean = isActivate === "true";
  const title = queryParams.get("title");
  const userInfo = useSelector((state) => state.auth.user);
  const { refetch } = useGetAllAssignmentsQuery({
    schoolId: userInfo.schoolId,
  });
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const [prevent, setPrevent] = useState(false);
  const handlePrevent = () => {
    setPrevent(!prevent);
  };
  const handleOpenAdd = () => {
    isActivateBoolean === true ? handlePrevent() : setOpenAdd(true);
  };

  const handleOpenDelete = () => {
    setAnchorEl(null);
    if (isActivateBoolean) {
      handlePrevent();
    } else {
      setOpenDelete(true);
    }
  };

  const handleOpenEdit = () => {
    setAnchorEl(null);
    if (isActivateBoolean) {
      handlePrevent();
    } else {
      setOpenEdit(true);
    }
  };

  const handleClickVariant = (variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar("Question deleted successfully", { variant });
  };
  const handleClickError = (variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar("Error trying to delete, try again", { variant });
  };

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adtoken}`,
  };
  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/Assignment/AssignmentQuestions/${id}`,
        { headers }
      );

      if (response.status !== 200) {
        console.log("Error: Unable to fetch data", response.status);
        return;
      }
      setLoading(false);
      const data = response.data;
      setQuestions(data?.data); // Access the response data directly
    } catch (error) {
      console.log("Error:", error);
      if (error.response) {
        if (error.response.status === 404) {
          console.log("Error: Resource not found (404)");
          setQuestions([]);
        }
        setLoading(false);
      }
    }
  };

  // Fetch questions when the component mounts or update
  useEffect(() => {
    fetchQuestions();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, id, row) => {
    setAnchorEl(event.currentTarget);
    setQuestionId(id);
    setEditData(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const idP = open ? "simple-popover" : undefined;
  const theme = useTheme();

  const deleteAssignment = async () => {
    setLoading(true);
    // setSuccess(false);
    try {
      const result = await api.deleteQuestion(questionId);

      if (!result?.data?.status) {
      }
      setLoading(false);
      // setSuccess(true);
      fetchQuestions();
      setOpenDelete(false);
      refetch();
      // navigate(-1);
      handleClickVariant("success");

      setAnchorEl(null);
    } catch (error) {
      setLoading(false);
      handleClickError("error");
      console.error(error);
    }
  };
  return (
    <>
      {loading && <LoaderLine />}
      <Box sx={{}}>
        <Title title=" Add Questions" />
      </Box>
      <Box align="right" sx={{ mt: 2 }}>
        <AddQuestion
          id={id}
          fetchQuestions={fetchQuestions}
          subject={subject}
          title={title}
          open={openAdd}
          handleOpen={handleOpenAdd}
          setOpen={setOpenAdd}
        />
      </Box>
      {/* <Typography sx={{ fontFamily: "gilroy-medium", fontSize: "20px" }}>
        English language(Oral English)
      </Typography> */}
      <Box
        sx={{
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Link to="/academicActivities?tab=2">
            <IconButton
              sx={{
                bgcolor: "#671e781a",
                width: "30px",
                height: "30px",
                mr: 2,
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Link>
          <Typography variant="caption" sx={{ fontFamily: "gilroy-regular" }}>
            Back to Assignment
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              fetchQuestions();
            }}
          >
            <CachedOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 700,
              fontFamily: "Gilroy-Regular",
              border: "1px solid #671E78",
            }}
            aria-label="customized table"
          >
            <TableHead
              sx={{
                backgroundColor: "rgba(103, 30, 120, 0.1)",
                width: "100%",
              }}
            >
              <TableRow>
                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>Question</StyledTableCell>
                <StyledTableCell>
                  <Box className="flex items-center justify-center">
                    <Typography sx={{ fontFamily: "gilroy-regular" }}>
                      {subject} - {title}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ fontFamily: "Gilroy-Regular" }}>
              {questions?.length === 0 ? (
                <></>
              ) : (
                questions?.length > 0 &&
                questions?.map((question, index) => (
                  <StyledTableRow
                    key={index}
                    style={
                      index % 2
                        ? { background: "rgba(103, 30, 120, 0.1)" }
                        : { background: "white" }
                    }
                  >
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {question.question
                        ? question.question
                        : "--No question found"}
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        value={question.questionid}
                        onClick={(event) =>
                          handleClick(event, question.questionid, question)
                        }
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Popover
        id={idP}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "#671E78",
            color: "#fff",
            fontFamily: "Gilroy-Regular",
            px: 3,
          }}
        >
          <MenuList sx={{ fontFamily: "Gilroy-Regular" }}>
            <MenuItem
              sx={{ fontFamily: "Gilroy-Regular" }}
              onClick={handleOpenEdit}
            >
              Edit
            </MenuItem>
            <MenuItem
              sx={{ fontFamily: "Gilroy-Regular" }}
              onClick={handleOpenDelete}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>

      {/* <Edit open={openEdit} onClose={handleCloseEdit} /> */}
      <EditQuestion
        open={openEdit}
        onClose={handleCloseEdit}
        id={questionId}
        assignmentId={id}
        data={editData}
        fetchQuestions={fetchQuestions}
      />

      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDelete}>
          <Box align="center">
            <RiDeleteBin6Line style={{ fontSize: "42px", color: "#d3302f" }} />
            <Box sx={{ mt: 3 }}>
              <Typography sx={{ fontFamily: "gilroy-bold" }}>
                You're about to delete Question
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "gilroy-regular",
                color: "#9c9c9c",
                fontSize: "12px",
              }}
            >
              This will delete the Qustion from the database
            </Typography>
            <Typography
              sx={{
                fontFamily: "gilroy-regular",
                color: "#9c9c9c",
                fontSize: "12px",
                mt: 2,
              }}
            >
              Are you sure?
            </Typography>
          </Box>
          <Box align="right" sx={{ mt: 2 }}>
            <Button
              sx={{
                color: "#333",
                textTransform: "initial",
                fontFamily: "gilroy-regular",
              }}
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button
              onClick={deleteAssignment}
              sx={{
                bgcolor: "#d3302f",
                color: "#fff",
                ml: 2,
                px: 3,
                textTransform: "initial",
                fontFamily: "gilroy-regular",
                "&:hover": {
                  bgcolor: "#d3302fb7",
                  color: "#fff",
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={prevent}
        onClose={handlePrevent}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={stylePrevent}>
          <Box align="right" sx={{}}>
            <IconButton onClick={handlePrevent}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Box p={3} sx={{ overflowY: "scroll", height: "200px" }}>
            <Box className="w-full h-full">
              <Typography sx={{ fontFamily: "Gilroy-Medium" }}>
                This assignment has been activated, you cannot delete or edit
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddAssignment;
