import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  Paper,
  TextField,
  Button,
  List,
  Box,
  IconButton,
  ListItemText,
  Grid,
  Avatar,
  ListItemAvatar,
  ListItemButton,
  Typography,
  Tooltip,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const useStyles = styled((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  chatContainer: {
    width: "80%",
    maxWidth: 400,
    maxHeight: 400,
    overflow: "auto",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  messageInput: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const ChatApp = () => {
  const classes = useStyles();
  const userInfo = useSelector((state) => state.auth.user);
  const [messages, setMessages] = React.useState([]);
  const [newMessage, setNewMessage] = React.useState("");
  const endpoint_two = process.env.REACT_APP_API_ENDPOINT_TWO;
  const api_version = process.env.REACT_APP_API_VERSION;
  const [data, setData] = useState(null);
  // const userInfo = useSelector((state) => state.auth.user);
  const adtoken = localStorage.getItem("admToken");
  const [loading, setLoading] = useState(false);
  const [sLoading, setSLoading] = useState(false);

  const { id } = useParams();

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${adtoken}`,
  };

  const fetchComment = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${endpoint_two}/api/v${api_version}/Assignment/AssignmentComments?AssignmentId=${id}`,
        { headers }
      );
      if (response.status !== 200) {
        setLoading(false);
        return;
      }

      const data = response.data;
      if (!data?.status) {
        console.log("Error: Data is null or has no status property.");
        return;
      }
      setLoading(false);
      setData(data?.data);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;
    const messageData = {
      comment: newMessage,
      assignmentId: Number(id),
      senderId: String(userInfo?.staffId),
      senderUserType: "T",
    };
    setSLoading(true);
    try {
      const response = await axios.post(
        `${endpoint_two}/api/v${api_version}/Assignment/Save-comment`,
        messageData,
        { headers }
      );
      if (response?.data?.statusCode === 2 && response?.data?.status) {
        const newMsg = {
          comment: newMessage,
          staffid: userInfo.staffId,
          author: "T",
          commentDate: new Date(),
        };
        setData([...data, newMsg]);
        setNewMessage("");
        fetchComment();
      }
    } catch (error) {
      toast.error("There was an error ", error);
    } finally {
      setSLoading(false);
    }
  };

  useEffect(() => {
    fetchComment();
  }, []);

  const trails = data
    ? [...data].sort(
        (a, b) => new Date(a.commentDate) - new Date(b.commentDate)
      )
    : [];

  return (
    <>
      <Box
        sx={{
          margin: "auto",
          width: "90%",
          height: "400px",
        }}
      >
        <Grid container spacing={2} sx={{ height: "380px" }}>
          <Grid item lg={4} sx={{ mt: 2 }}>
            <Box>
              <List>
                <ListItemButton
                  sx={{ bgcolor: "#eef5e7", borderRight: "2px solid #671e78" }}
                >
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography
                      sx={{ fontFamily: "Gilroy-regular", fontSize: "14px" }}
                    >
                      {" "}
                      Student
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Box>
          </Grid>
          <Grid item lg={8}>
            <Box
              sx={{
                height: "300px",
                bgcolor: "#E5E5E5b7",
                my: 2,
                borderRadius: 2,
                overflowY: "scroll",
                p: 2,
              }}
            >
              {data && data?.length === 0 && (
                <>
                  <Box>
                    <Typography>No Comment Available</Typography>
                  </Box>
                </>
              )}
              {loading && (
                <Typography sx={{ fontFamily: "Gilroy-regular" }}>
                  Loading...
                </Typography>
              )}
              <List>
                {trails?.map((message, index) => {
                  const outbox = message.staffid == userInfo.staffId;
                  const isStudent = message.author === "S";
                  return (
                    <div
                      className={`${
                        isStudent ? "items-start" : "items-end"
                      } flex flex-col my-1`}
                      key={index}
                    >
                      {/* {!outbox &&
                        (isStudent ? (
                          <div className="flex gap-1 items-center mt-1">
                            <img
                              src={message.studentImage || profileImg}
                              className="h-5 w-5 rounded-full"
                            />
                            <span className="text-xs ">
                              {message.studentName}
                            </span>
                          </div>
                        ) : (
                          <div className="flex gap-1 items-center mt-1">
                            <img
                              src={message.teacherImage || profileImg}
                              className="h-5 w-5 rounded-full"
                            />
                            <span className="text-xs ">
                              {message.teacherName}
                            </span>
                          </div>
                        ))} */}
                      <Typography
                        sx={{
                          fontFamily: "Gilroy-regular",
                          textAlign: outbox ? "right" : "left",
                          fontSize: "13px",
                          width: "fit-content",
                          maxWidth: "50%",
                          bgcolor: outbox ? "#fff" : "#671e78",
                          p: 1,
                          borderRadius: "20px",
                          borderTopLeftRadius: !outbox ? 0 : "20px",
                          borderTopRightRadius: outbox ? 0 : "20px",
                          color: !outbox ? "#fff" : "#671e78",
                        }}
                      >
                        {message?.comment}
                      </Typography>
                    </div>
                  );
                })}
              </List>
            </Box>
            <Box
              className={classes.messageInput}
              sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
            >
              <TextField
                fullWidth
                disabled={sLoading}
                // multiline
                placeholder="Type here..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                InputProps={{
                  style: {
                    fontFamily: "gilroy-regular",
                    fontSize: "13px",
                    borderRadius: "20px",
                    offset: " 0.5px solid #671E78",
                    color: "#333",
                    border: "0.5px solid #671E78",
                    height: "40px",

                    // Replace with your desired font family
                  },
                }}
              />
              <IconButton
                disabled={sLoading}
                onClick={handleSendMessage}
                sx={{
                  bgcolor: "#671e78",
                  display: "grid",
                  placeItem: "center",
                  height: "40px",
                  width: "40px",
                  "&:hover": {
                    bgcolor: "#671e78b7",
                  },
                }}
              >
                <SendIcon sx={{ color: "#fff", fontSize: "15px" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChatApp;
