import React from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Table,
  TableHead,
  TextField,
  TableCell,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  Skeleton,
  Modal,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../assets/colors.json";
import { useGetLessonSessionsQuery } from "../../api/lessonNoteApi";
import { useFormik } from "formik";
import * as Yup from "yup";

const CreateNoteModal = ({ onClose }) => {
  const { data: sessions, isLoading: sessionsLoading } =
    useGetLessonSessionsQuery();

  const validationSchema = Yup.object().shape({
    SessionId: Yup.string().required("School Year is required"),
    Term: Yup.string().required("Term is required"),
    SubjectId: Yup.string().required("Subject is required"),
    ClassName: Yup.string().required("Class Arm is required"),
    WeekId: Yup.string().required("Week is required"),
  });

  const initialValues = {
    SessionId: "",
    Term: "",
    SubjectId: "",
    ClassName: "",
    WeekId: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log("Form Submitted:", values);
      // Handle form submission logic here
    },
  });

  return (
    <div className="absolute top-1/2 left-1/2 w-full max-w-[800px] bg-white shadow-lg rounded-md border border-[#891e78] p-4 transform -translate-x-1/2 -translate-y-1/2">
      <div className="flex justify-end ">
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </div>
      <h1 className="text-center text-2xl lg:text-3xl font-semibold">
        Add Lesson Note Setup
      </h1>
      <form onSubmit={formik.handleSubmit}>
        <Box
          p={3}
          sx={{
            overflowY: {
              xs: "scroll",
              sm: "scroll",
              md: "scroll",
              lg: "scroll",
              xl: "auto",
            },
            height: {
              xs: "550px",
              sm: "550px",
              md: "550px",
              lg: "550px",
              xl: "auto",
            },
          }}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                    //   onClick={fetchSubjects}
                  >
                    School Year
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    {...formik.getFieldProps("SessionId")}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Year</em>
                    </MenuItem>
                    {sessionsLoading && (
                      <Box>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "2rem", mx: 2 }}
                        />
                        <Skeleton
                          variant="rounded"
                          height={60}
                          sx={{ mx: 2 }}
                        />
                      </Box>
                    )}
                    {sessions.length === 0 && !sessionsLoading ? (
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "12px",
                            fontWeight: "bolder",
                            color: "#333",
                            fontFamily: "Gilroy-Medium",
                          }}
                        >
                          No Sessions Available
                        </Typography>
                      </Box>
                    ) : (
                      sessions.length > 0 &&
                      sessions?.map((session) => (
                        <MenuItem
                          key={session.id}
                          value={session.id}
                          sx={{
                            fontFamily: "gilroy-regular",
                            fontSize: "13px",
                          }}
                        >
                          {session.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {formik.touched.SessionId && formik.errors.SessionId ? (
                    <div className="text-red-600 text-xs">
                      {formik.errors.SessionId}
                    </div>
                  ) : null}
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box>
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                    //   onClick={fetchSubjects}
                  >
                    Term
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    {...formik.getFieldProps("Term")}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                        // border: "1px solid #671E78",
                        // Replace with your desired font family
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Term</em>
                    </MenuItem>
                    {/* {subjectLoader && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )} */}
                    {/* {subjects.length === 0 && !subjectLoader ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Available
                      </Typography>
                    </Box>
                  ) : (
                    subjects.length > 0 &&
                    subjects.map((subject) => (
                      <MenuItem
                        value={subject.subjectId}
                        onClick={(e) => {
                          setSubjectName(subject.subjectName);
                          setSubjectID(subject.subjectId);
                          // No need to call fetchClasses() here, it's triggered by useEffect
                          setSelectedClass([]);
                        }}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {subject.subjectName}
                      </MenuItem>
                    ))
                  )} */}
                  </Select>
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box>
                  {/* <Button onClick={handleClickVariant("success")}>CLick me</Button> */}
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                    //   onClick={fetchSubjects}
                  >
                    Subject
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    value={""}
                    //   onChange={handleChange}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                        // border: "1px solid #671E78",
                        // Replace with your desired font family
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Subject</em>
                    </MenuItem>
                    {/* {subjectLoader && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )} */}
                    {/* {subjects.length === 0 && !subjectLoader ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Available
                      </Typography>
                    </Box>
                  ) : (
                    subjects.length > 0 &&
                    subjects.map((subject) => (
                      <MenuItem
                        value={subject.subjectId}
                        onClick={(e) => {
                          setSubjectName(subject.subjectName);
                          setSubjectID(subject.subjectId);
                          // No need to call fetchClasses() here, it's triggered by useEffect
                          setSelectedClass([]);
                        }}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {subject.subjectName}
                      </MenuItem>
                    ))
                  )} */}
                  </Select>
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box>
                  {/* <Button onClick={handleClickVariant("success")}>CLick me</Button> */}
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                    //   onClick={fetchSubjects}
                  >
                    Class Arm
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    value={""}
                    //   onChange={handleChange}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                        // border: "1px solid #671E78",
                        // Replace with your desired font family
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Class</em>
                    </MenuItem>
                    {/* {subjectLoader && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )} */}
                    {/* {subjects.length === 0 && !subjectLoader ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Available
                      </Typography>
                    </Box>
                  ) : (
                    subjects.length > 0 &&
                    subjects.map((subject) => (
                      <MenuItem
                        value={subject.subjectId}
                        onClick={(e) => {
                          setSubjectName(subject.subjectName);
                          setSubjectID(subject.subjectId);
                          // No need to call fetchClasses() here, it's triggered by useEffect
                          setSelectedClass([]);
                        }}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {subject.subjectName}
                      </MenuItem>
                    ))
                  )} */}
                  </Select>
                </Box>
              </Grid>
              <Grid item lg={12}>
                <Box>
                  {/* <Button onClick={handleClickVariant("success")}>CLick me</Button> */}
                  <InputLabel
                    sx={{
                      mb: 1.5,
                      mt: 1,
                      fontSize: "12px",
                      fontWeight: "bolder",
                      color: "#000",
                      fontFamily: "Gilroy-Medium",
                    }}
                    //   onClick={fetchSubjects}
                  >
                    Week
                  </InputLabel>

                  <Select
                    fullWidth
                    placeholder="dssss"
                    size="small"
                    value={""}
                    //   onChange={handleChange}
                    sx={{
                      fontSize: "13px",
                      border: "0.5px solid #671E78",
                      fontFamily: "gilroy-regular",
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        fontSize: "13px",
                        borderRadius: "10px",
                        fontFamily: "gilroy-regular",
                        // border: "1px solid #671E78",
                        // Replace with your desired font family
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em> Choose Week</em>
                    </MenuItem>
                    {/* {subjectLoader && (
                    <Box>
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "2rem", mx: 2 }}
                      />
                      <Skeleton variant="rounded" height={60} sx={{ mx: 2 }} />
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1rem", mx: 2 }}
                      />
                    </Box>
                  )} */}
                    {/* {subjects.length === 0 && !subjectLoader ? (
                    <Box>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bolder",
                          color: "#333",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        No Subject Available
                      </Typography>
                    </Box>
                  ) : (
                    subjects.length > 0 &&
                    subjects.map((subject) => (
                      <MenuItem
                        value={subject.subjectId}
                        onClick={(e) => {
                          setSubjectName(subject.subjectName);
                          setSubjectID(subject.subjectId);
                          // No need to call fetchClasses() here, it's triggered by useEffect
                          setSelectedClass([]);
                        }}
                        sx={{
                          fontFamily: "gilroy-regular",
                          fontSize: "13px",
                        }}
                      >
                        {subject.subjectName}
                      </MenuItem>
                    ))
                  )} */}
                  </Select>
                </Box>
              </Grid>

              {/* <Grid item lg={6} xs={12}>
              <Box>
                <InputLabel
                  sx={{
                    mb: 1.5,
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: "bolder",
                    color: "#000",
                    fontFamily: "Gilroy-Medium",
                  }}
                  //   onClick={fetchSubjects}
                >
                  Upload Note
                </InputLabel>
              </Box>
              <TextField
                type="file"
                fullWidth
                onChange={handleFileChange}
                InputProps={{
                  style: {
                    fontFamily: "Gilroy-regular",
                    fontSize: "13px",
                    borderRadius: "5px",
                    offset: " 0.5px solid #671E78",
                    border: " 0.5px solid #671E78",
                    padding: 0,
                  },
                  inputProps: {
                    style: {
                      padding: "9px", // Target internal input padding
                    },
                  },
                }}
              />
            </Grid> */}
            </Grid>
            <Box mt={5}>
              <Button
                //   disabled={!score || !description || !title}
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "initial",
                  backgroundColor: colors.primary,
                }}
                //   onClick={handleCreateAssignment}
              >
                Add Note
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </div>
  );
};

export default CreateNoteModal;
