/* eslint-disable eqeqeq */
import React, { useState, useCallback, useEffect } from "react";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import {
  AccountSummaryWrapper,
  TabContainer,
  InlineLabelGroup,
  InlineLabelWrapper,
} from "../../pages/Dashboard/styles/dashboard.style";
import {
  InputWrapper,
  AuthButton,
  ButtonFlex,
} from "../../pages/auth/Login.style";
import { toast } from "react-toastify";
import { checkAccess, isNumber } from "../../services/utils";
import colors from "../../assets/colors.json";

import Select from "../select/select";
import FormLoader from "../../components/FormLoader";
import {
  MySubject,
  StudentListScore,
  MySubjectClasses,
  getSessions,
  getTerms,
  UpdateStudentScore,
  GetFixedGrades,
  UpdateStudentScoreSingle,
} from "../../services/Subject";
import ErrorService from "../../services/errorService";
import { useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";

const UpdateScore = () => {
  const [showForm, setShowForm] = useState(true);
  const [allData, setAllData] = useState([]);
  const [pageRecords, setPageRecords] = useState(0);
  const [formIsValid, setFormIsValid] = useState(false);
  const { admToken, user, accessObj } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [caMark, setCaMark] = useState(0);
  const [examMark, setExamMark] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [CAData, setCAData] = useState({});
  const [examData, setExamData] = useState({});
  const [edit, showEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [inputValue1, setInputValue1] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  // const [isArr, setIsArr] = useState([]);

  // const handleArr = (item) => {
  //   setIsArr((prev) => {
  //     // Check if both input fields are empty
  //     if (item.caexam.ca === "" && item.caexam.exam === "") {
  //       // Remove the item from the array if it exists
  //       return prev.filter((prevItem) => prevItem.studentId !== item.studentId);
  //     }

  //     // Check if the item is already in the isArr array
  //     const itemIndex = prev.findIndex(
  //       (prevItem) => prevItem.studentId === item.studentId
  //     );

  //     // If the item is not in the array, add it
  //     if (itemIndex === -1) {
  //       const updatedArr = [...prev];
  //       updatedArr.push({
  //         ...item,
  //         caexam: {
  //           ca: CAData?.caexam?.ca,
  //           exam: examData?.caexam?.exam,
  //           wa: CAData.caexam?.ca + examData?.caexam?.exam,
  //           isRecordSaved: item.isRecordSaved,
  //         },
  //       });
  //       return updatedArr;
  //     }

  //     // If the item is already in the array, return the previous array
  //     return prev;
  //   });
  // };

  const [subject, setSubject] = useState({
    id: "",
    name: "",
  });
  const [loadSub, setLoadSub] = useState(false);
  const [subjectClasses, setSubjectClasses] = useState([]);
  const [classObj, setClassObj] = useState({
    id: "",
    name: "",
  });
  const [session, setSession] = useState({
    id: "",
    name: "",
  });
  const [sessions, setSessions] = useState([]);
  const [term, setTerm] = useState({
    id: "",
    name: "",
  });

  const [deleteLoading, setDeleteLoading] = useState(false);

  const getScores = useCallback(async () => {
    try {
      const res = await GetFixedGrades({
        token: admToken,
        schoolId: user.schoolId,
      });
      if (res.status && res.statusCode === 2 && Array.isArray(res.data)) {
        let _obj = res.data[0];

        if (!isNaN(_obj.ca)) {
          // console.log(_obj.ca);
          setCaMark(parseFloat(_obj.ca));
        }

        if (!isNaN(_obj.exam)) {
          setExamMark(parseFloat(_obj.exam));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const [terms, setTerms] = useState([]);
  const schoolTerms = useCallback(async () => {
    try {
      const res = await getTerms(admToken);
      res.status &&
        res.statusCode === 2 &&
        setTerms(res?.data?.filter((item) => item?.isActive));
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const schoolSessions = useCallback(async () => {
    try {
      const res = await getSessions(admToken);

      res.status && res.statusCode === 2 && setSessions(res.data);
      (res.status === 500 || res.data.status === 400 || !res.status) &&
        ErrorService.displayErrorAlert(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [admToken]);
  const getSubjects = useCallback(async () => {
    try {
      const res = await MySubject(admToken, user.schoolId);

      res.status && res.statusCode === 2 && setSubjects(res.data);
      (res.status === 500 || res.status === 400) &&
        ErrorService.displayErrorAlert(res.data);
      setLoadSub(false);
    } catch (error) {
      console.log(error);
      setLoadSub(false);
    }
  }, [admToken, user]);
  useEffect(() => {
    getSubjects();
    schoolTerms();
    schoolSessions();
    getScores();
  }, [
    getSubjects,
    schoolSessions,
    schoolTerms,
    getScores,
    inputValue,
    inputValue1,
  ]);

  const getClasses = useCallback(
    async (id) => {
      try {
        setLoadSub(true);
        const res = await MySubjectClasses(admToken, user.schoolId, id);

        res.status && res.statusCode === 2 && setSubjectClasses(res.data);
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);
        setLoadSub(false);
      } catch (error) {
        setLoadSub(false);
        console.log(error);
      }
    },
    [admToken, user]
  );
  const handleChange = (event, value) => {
    setCurrentPage(value);
    getStudentScoreList(value);
  };
  const getStudentScoreList = useCallback(
    async (value) => {
      try {
        let data = {
          schoolId: user.schoolId,
          subjectId: subject.id,
          classId: classObj.id,
          sessionId: session.id,
          termId: term.id,
          recordType: 0,
          pageSize: 10,
          pageNumber: value ? value : currentPage,
        };

        setLoading(true);
        const res = await StudentListScore(admToken, data);
        // console.log(res);
        if (res.status && res?.statusCode === 2) {
          setPageRecords(res?.totalRecord);
          setAllData(res?.data);
          if (res.data.length > 0) {
            setShowForm(false);
          }
          if (res.data.length === 0) {
            toast.error("No Students Available");
          }
        }
        (res.status === 500 || res.status === 400 || !res.status) &&
          ErrorService.displayErrorAlert(res);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    [
      admToken,
      classObj.id,
      session.id,
      subject.id,
      term.id,
      user.schoolId,
      currentPage,
    ]
  );
  useEffect(() => {
    if (allData.length > 0) {
      const isPositive = allData.some((item) => {
        // Ensure item?.caexam is a valid object
        return (
          item?.caexam &&
          typeof item.caexam === "object" &&
          Object.values(item.caexam).some(
            (value) => value !== null && value !== ""
          )
        );
      });
      setFormIsValid(isPositive);
    } else {
      setFormIsValid(false);
    }
  }, [allData]);

  const updateSingleResult = async (item, editId) => {
    let data = {
      schoolId: user.schoolId,
      subjectId: subject.id,
      classId: classObj.id,
      sessionId: session.id,
      termId: term.id,
      studentId: CAData?.studentId,
      studentCAEXAM: {
        ca: CAData?.caexam?.ca,
        exam: examData?.caexam?.exam,
        wa: Number(item?.caexam?.ca) + Number(item?.caexam?.exam),
      },
    };
    if (item.studentId === editId) {
      if (
        CAData?.caexam?.ca !== undefined &&
        CAData?.caexam?.ca !== 0 &&
        CAData?.caexam?.ca !== null &&
        examData?.caexam?.exam !== undefined &&
        examData?.caexam?.exam !== 0 &&
        examData?.caexam?.exam !== null
      ) {
        try {
          setLoading(true);
          const res = await UpdateStudentScoreSingle(admToken, data);
          if (res.status && res.statusCode === 2) {
            getStudentScoreList(currentPage);
            setInputValue(0);
            showEdit(false);
          }
          if (
            res.status === 500 ||
            res.status === 400 ||
            res.data.status === 400 ||
            !res.status
          ) {
            ErrorService.displayErrorAlert(res.data);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
        setCAData((prev) => ({ ...prev, caexam: { ca: 0 } }));
        setExamData((prev) => ({ ...prev, caexam: { exam: 0 } }));
      }
    }
  };

  const editSingleResult = async (item, editId) => {
    let data = {
      schoolId: user.schoolId,
      subjectId: subject.id,
      classId: classObj.id,
      sessionId: session.id,
      termId: term.id,
      studentId:
        // CAData?.studentId || examData?.studentId
        item.studentId,
      studentCAEXAM: {
        ca: CAData?.caexam?.ca || item?.caexam?.ca,
        exam: examData?.caexam?.exam || item?.caexam?.exam,
        wa: Number(item?.caexam?.ca) + Number(item?.caexam?.exam),
      },
      isDelete: false,
    };
    if (item.studentId === editId) {
      if (
        (CAData?.caexam?.ca !== undefined && CAData?.caexam?.ca !== 0) ||
        (examData?.caexam?.exam !== undefined && examData?.caexam?.exam !== 0)
      ) {
        try {
          setLoading(true);
          const res = await UpdateStudentScoreSingle(admToken, data);
          if (res.status && res.statusCode === 2) {
            getStudentScoreList(currentPage);
            setInputValue(0);
            showEdit(false);
          }
          if (
            res.status === 500 ||
            res.status === 400 ||
            res.data.status === 400 ||
            !res.status
          ) {
            ErrorService.displayErrorAlert(res.data);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
        // setCAData((prev) => ({ ...prev, caexam: { ca: 0 } }));
        // setExamData((prev) => ({ ...prev, caexam: { exam: 0 } }));
      }
    }
  };

  const deleSingleResult = async (item, editId) => {
    let data = {
      schoolId: user.schoolId,
      subjectId: subject.id,
      classId: classObj.id,
      sessionId: session.id,
      termId: term.id,
      studentId: item.studentId,
      studentCAEXAM: {
        ca: CAData?.caexam?.ca || item?.caexam.ca,
        exam: examData?.caexam?.exam || item?.caexam?.exam,
        wa: Number(item?.caexam?.ca) + Number(item?.caexam?.exam),
      },
      isDelete: true,
    };
    if (item.studentId === editId) {
      try {
        setDeleteLoading(true);
        const res = await UpdateStudentScoreSingle(admToken, data);
        if (res.status && res.statusCode === 2) {
          getStudentScoreList(currentPage);
          setInputValue(0);
          showEdit(false);
        }
        if (
          res.status === 500 ||
          res.status === 400 ||
          res.data.status === 400 ||
          !res.status
        ) {
          ErrorService.displayErrorAlert(res.data);
        }
        setDeleteLoading(false);
      } catch (error) {
        setDeleteLoading(false);
        console.log(error);
      }
    }
  };

  // const updateAction = useCallback(async () => {
  //   const arr = getArr();
  //   try {
  //     let data = {
  //       schoolId: user.schoolId,
  //       subjectId: subject.id,
  //       classId: classObj.id,
  //       sessionId: session.id,
  //       termId: term.id,
  //       studentCAEXAM: arr,
  //     };

  //     setFormLoading(true);
  //     const res = await UpdateStudentScore(admToken, data);
  //     if (res.status && res.statusCode === 2) {
  //       toast.success(res.message);
  //       getStudentScoreList(currentPage);
  //       setInputValue(0);
  //     }

  //     if (
  //       res.status === 500 ||
  //       res.status === 400 ||
  //       res.data.status === 400 ||
  //       !res.status
  //     ) {
  //       ErrorService.displayErrorAlert(res.data);
  //     }
  //     setFormLoading(false);
  //   } catch (error) {
  //     setFormLoading(false);

  //     console.log(error);
  //   }
  // }, [
  //   admToken,
  //   classObj.id,
  //   getArr,
  //   session.id,
  //   subject.id,
  //   term.id,
  //   user.schoolId,
  // ]);

  const updateScoreState = (e, type, id) => {
    const updateData = allData.map((student) => {
      if (student.studentId == id) {
        if (type === "ca") {
          setCAData({
            ...student,
            caexam: {
              ...student?.caexam,
              ca:
                Number(e.target.value) > Number(caMark)
                  ? 0
                  : Number(e.target.value),
            },
          });
          return {
            ...student,
            caexam: {
              ...student?.caexam,
              ca:
                Number(e.target.value) > Number(caMark)
                  ? 0
                  : Number(e.target.value),
            },
          };
        }
        if (type === "exam") {
          setExamData({
            ...student,
            caexam: {
              ...student?.caexam,
              exam:
                Number(e.target.value) > Number(examMark)
                  ? 0
                  : Number(e.target.value),
            },
          });
          return {
            ...student,
            caexam: {
              ...student?.caexam,
              exam:
                Number(e.target.value) > Number(examMark)
                  ? 0
                  : Number(e.target.value),
            },
          };
        }
      }

      return student;
    });

    setAllData(updateData);
  };

  const CTotal = () => {
    let totalScore = 0;
    for (let entry of allData) {
      if (
        typeof entry?.caexam?.ca === "number" ||
        (!isNaN(entry?.caexam?.ca) &&
          entry?.caexam?.ca !== "" &&
          entry?.caexam?.ca !== null)
      ) {
        totalScore += Number(entry?.caexam?.ca < 0 ? 0 : entry?.caexam?.ca);
      }
    }
    return totalScore;
  };
  const ETotal = () => {
    let totalScore = 0;
    for (let entry of allData) {
      if (
        typeof entry?.caexam?.exam === "number" ||
        (!isNaN(entry?.caexam?.exam) &&
          entry?.caexam?.exam !== "" &&
          entry?.caexam?.exam !== null)
      ) {
        totalScore += Number(entry?.caexam?.exam < 0 ? 0 : entry?.caexam?.exam);
      }
    }
    return totalScore;
  };
  return (
    <>
      {/* {showModal && (
        <Modal size={"large"} onClose={setShowModal} content={content} />
      )} */}

      <div
        onClick={() => setShowForm(!showForm)}
        style={{
          backgroundColor: colors.primary,
          borderRadius: "10px",
          marginTop: "0.3rem",
          padding: "0.4rem",
          display: "flex",
          justifyContent: "space-between",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <div>
          <span>
            {session.name}
            {session.name.length > 0 ? " - " : " "}
            {term.name}
            {term.name.length > 0 ? " - " : " "}
            {subject.name}
            {subject.name.length > 0 ? " - " : " "}
            {classObj.name}
          </span>
        </div>
        {showForm ? (
          <BiSolidDownArrow color="white" />
        ) : (
          <BiSolidUpArrow color="white" />
        )}
      </div>

      {showForm && (
        <>
          <InlineLabelGroup>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Session"
                  index="session" // no
                  selected={session.id} // false
                  options={sessions.filter((i) => i.isActive)}
                  setSelected={(val) => {
                    setSession({ ...session, id: val.id, name: val.session });
                    setAllData([]);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                <Select
                  title="Term"
                  index="term" // no
                  selected={term.id} // false
                  options={terms}
                  setSelected={(val) => {
                    setTerm({ ...term, id: val.id, name: val.term });
                    setAllData([]);
                  }}
                  // color="white"
                />
              </InputWrapper>
            </InlineLabelWrapper>
          </InlineLabelGroup>

          <InlineLabelGroup>
            <InlineLabelWrapper flex={2}>
              <InlineLabelGroup style={{ alignItems: "start" }}>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title="Subject"
                      index="subjectName" // no
                      selected={subject.id} // false
                      options={subjects}
                      thirdOption="subjectId" // false
                      setSelected={(val) => {
                        // console.log(val);
                        setAllData([]);
                        setClassObj({ ...classObj, id: "", name: "" });

                        setSubject({
                          ...subject,
                          id: val.subjectId,
                          name: val.subjectName,
                        });
                        getClasses(val.subjectId);
                        setPageRecords(0);
                      }}
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
                <InlineLabelWrapper flex={1}>
                  <InputWrapper pt="0px" style={{ paddingBottom: "0px" }}>
                    <Select
                      title={loadSub ? "Loading..." : "Class"}
                      index="className" // no
                      selected={classObj.id} // false
                      options={subjectClasses}
                      thirdOption="classId"
                      extraTitle="classArm"
                      setSelected={(val) => {
                        setAllData([]);
                        setClassObj({
                          ...classObj,
                          id: val.classId,
                          name: `${val.className} ${val.classArm}`,
                        });
                        setPageRecords(0);
                        // setClassId(val.classId);
                      }}
                    />
                  </InputWrapper>
                </InlineLabelWrapper>
              </InlineLabelGroup>
            </InlineLabelWrapper>
            <InlineLabelWrapper flex={1}>
              <ButtonFlex>
                <AuthButton
                  mt="0rem"
                  disabled={
                    loading ||
                    classObj.id === "" ||
                    subject.id === "" ||
                    term.id === "" ||
                    session.id === ""
                  }
                  onClick={() => getStudentScoreList(1)}
                >
                  <div style={{ textAlign: "center" }}>
                    {loading ? (
                      <FormLoader color={colors.primary} />
                    ) : (
                      "Load Student"
                    )}
                  </div>
                </AuthButton>
              </ButtonFlex>
            </InlineLabelWrapper>
          </InlineLabelGroup>
        </>
      )}
      <TabContainer style={{ borderRadius: "10px", paddingBottom: "0px" }}>
        <AccountSummaryWrapper pt="0px">
          <table>
            <tr
              className="table-head"
              style={{ backgroundColor: colors.primary, color: "white" }}
            >
              <th>Students ({pageRecords}) </th>
              <th>CA Score({caMark})</th>
              <th>Exam Score({examMark})</th>
              <th>Total Score(100)</th>
              <th>Action</th>
            </tr>
            {allData?.length > 0 ? (
              <>
                {allData?.map((item, index) => {
                  const input1 = !item?.caexam?.ca
                    ? ""
                    : item?.caexam?.ca < 0 || item?.caexam?.ca === -1
                    ? null
                    : item?.caexam?.ca;
                  const input2 = !item?.caexam?.exam
                    ? ""
                    : item?.caexam?.exam < 0 || item?.caexam?.exam === -1
                    ? null
                    : item?.caexam?.exam;
                  return (
                    <tr key={index}>
                      <td>
                        <span>
                          {index + 1}. {item.fullName} ({item.admissionNo})
                        </span>
                      </td>
                      <td>
                        <input
                          type="text"
                          disabled={
                            item?.activateUpdateButton === 1 ||
                            !edit & (item?.caexam?.isRecordSaved === true)
                          }
                          onKeyDown={(e) => isNumber(e)}
                          value={input1}
                          style={{ width: "75px" }}
                          onChange={(e) => {
                            updateScoreState(e, "ca", item?.studentId);
                            setInputValue1(Number(e.target.value));
                          }}
                          onBlur={() =>
                            updateSingleResult(item, item?.studentId)
                          }
                        />
                      </td>
                      <td>
                        <input
                          disabled={
                            item?.activateUpdateButton === 1 ||
                            !edit & (item?.caexam?.isRecordSaved === true)
                          }
                          onKeyDown={(e) => isNumber(e)}
                          type="text"
                          value={input2}
                          style={{ width: "75px" }}
                          onChange={(e) => {
                            updateScoreState(e, "exam", item.studentId);
                            setInputValue(Number(e.target.value));
                          }}
                          onBlur={() =>
                            updateSingleResult(item, item?.studentId)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          onKeyDown={(e) => {
                            isNumber(e);
                          }}
                          value={
                            (item?.caexam?.ca === null ||
                            item?.caexam?.ca === "" ||
                            item?.caexam?.ca < 0
                              ? null
                              : Number(item?.caexam?.ca)) +
                            (item?.caexam?.exam === null ||
                            item?.caexam?.exam === "" ||
                            item?.caexam?.exam < 0
                              ? null
                              : Number(item?.caexam?.exam))
                          }
                          disabled={true}
                          style={{ width: "75px" }}
                        />
                      </td>
                      <td>
                        {item?.caexam?.isRecordSaved ? (
                          <>
                            {edit && editId === item?.studentId ? (
                              <div className="flex justify-center">
                                <div className="flex gap-5 items-center">
                                  <button
                                    className="border-[#671E78] border px-[10px] py-[5px] rounded-sm"
                                    onClick={() => {
                                      editSingleResult(item, editId, false);
                                    }}
                                  >
                                    {loading && editId === item.studentId ? (
                                      <FormLoader color={colors.primary} />
                                    ) : (
                                      <SaveIcon className="text-[#671E78]" />
                                    )}
                                  </button>
                                  <button
                                    className="border-[#671E78] border px-[10px] py-[5px] rounded-sm"
                                    onClick={() => {
                                      deleSingleResult(item, editId);
                                    }}
                                  >
                                    {deleteLoading &&
                                    editId === item.studentId ? (
                                      <FormLoader color={colors.primary} />
                                    ) : (
                                      <DeleteIcon className="text-red-500" />
                                    )}
                                  </button>
                                  <button
                                    className="border-[#671E78] border px-[10px] py-[5px] rounded-sm"
                                    onClick={() => {
                                      showEdit(false);
                                      setEditId("");
                                    }}
                                  >
                                    <CloseIcon className="text-[#671E78]" />
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <button
                                  disabled={item?.activateUpdateButton === 1}
                                  className="border-[rgb(103,30,120)] border px-[10px] py-[5px] rounded-sm"
                                  onClick={() => {
                                    showEdit(true);
                                    setEditId(item.studentId);
                                  }}
                                >
                                  <CreateIcon className="text-[#671E78]" />
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          <button
                            className="border-[#671E78] border px-[10px] py-[5px] rounded-sm"
                            disabled={
                              loading ||
                              item?.activateUpdateButton === 1 ||
                              Number(item?.caexam?.ca) < 1 ||
                              Number(item?.caexam?.exam) < 1
                              // isArr.length >= 21
                            }
                            // onClick={() => {
                            //   // updateSingleResult(item, item?.studentId);
                            // }}
                          >
                            {loading ? (
                              <FormLoader color={colors.primary} />
                            ) : (
                              <SaveIcon className="text-[#671E78]" />
                            )}
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td style={{ color: colors.primary, fontWeight: "bold" }}>
                    Total
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="text"
                      onKeyDown={(e) => isNumber(e)}
                      value={CTotal() <= 0 ? "" : CTotal()}
                      // value={opt}
                      disabled={true}
                      style={{
                        width: "75px",
                        textAlign: "center",
                        backgroundColor: colors.primary,
                        color: "white",
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      onKeyDown={(e) => isNumber(e)}
                      value={ETotal() <= 0 ? "" : ETotal()}
                      // value={opt}
                      disabled={true}
                      style={{
                        width: "75px",
                        textAlign: "center",
                        backgroundColor: colors.primary,
                        color: "white",
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      onKeyDown={(e) => isNumber(e)}
                      value={
                        ETotal() + CTotal() === 0 ? "" : ETotal() + CTotal()
                      }
                      // value={opt}
                      disabled={true}
                      style={{
                        width: "75px",
                        textAlign: "center",
                        backgroundColor: colors.primary,
                        color: "white",
                      }}
                    />
                  </td>
                  <td></td>
                </tr>
              </>
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center" }}>
                  Students List Are Not Available
                </td>
              </tr>
            )}
          </table>
        </AccountSummaryWrapper>
      </TabContainer>
      <Pagination
        count={Math.ceil(pageRecords / 10)}
        page={currentPage}
        onChange={handleChange}
      />
      {/* {inputValue > 0 && inputValue1 > 0 ? (
        <div
          style={{ display: "flex", justifyContent: "end" }}
          className="gap-5"
        >
          {allData.length > 0 && checkAccess(accessObj.rights, "Update") && (
            <AuthButton
              onClick={updateAction}
              disabled={
                formLoading ||
                !formIsValid ||
                allData.some((i) => i.activateUpdateButton === 1)
              }
            >
              {formLoading ? (
                <FormLoader />
              ) : allData.some((i) => i.activateUpdateButton === 1) ? (
                "Locked"
              ) : (
                "Save All"
              )}{" "}
            </AuthButton>
          )}
        </div>
      ) : null} */}
    </>
  );
};

export default UpdateScore;
