import { API } from "./constant";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const lessonNoteApi = createApi({
  reducerPath: "lessonNote",
  baseQuery,
  tagTypes: ["LessonNotes"],
  endpoints: (builder) => ({
    getAllLessonNotes: builder.query({
      query: ({ searchText = "", pageNumber = 1, pageSize = 10 } = {}) => ({
        url: API.ALL_LESSON_NOTES,
        method: "GET",
        params: {
          pageNumber,
          pageSize,
          ...(searchText ? { searchText } : {}),
        },
      }),
      providesTags: (result) =>
        result && result.status // Check if result is defined and has status
          ? [
              ...result.data.records.map(({ id }) => ({
                type: "LessonNotes",
                id,
              })),
              { type: "LessonNotes", id: "LIST" },
            ]
          : [{ type: "LessonNotes", id: "LIST" }],
    }),
    getLessonSessions: builder.query({
      query: () => ({
        url: API.LESSON_NOTES_SESSIONS,
        method: "GET",
      }),
    }),
    getLessonWeeks: builder.query({
      query: () => ({
        url: API.LESSON_NOTES_WEEKS,
        method: "GET",
      }),
    }),
    getLessonTerms: builder.query({
      query: () => ({
        url: API.LESSON_NOTES_TERMS,
        method: "GET",
      }),
    }),
    // get: builder.query({
    //   query: () => ({
    //     url: API.LESSON_NOTES_WEEKS,
    //     method: "GET",
    //   }),
    // }),
    // getWeeks: builder.query({
    //   query: () => ({
    //     url: API.LESSON_NOTES_WEEKS,
    //     method: "GET",
    //   }),
    // }),
  }),
});

export const { useGetAllLessonNotesQuery, useGetLessonSessionsQuery } =
  lessonNoteApi;
